<template>
  <div class="">
    <van-popup :value="show" position="bottom" @click-overlay="cancel">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="label"
        @confirm="confirm"
        @cancel="cancel"
      />

      <!--  -->
    </van-popup>

    <!--  -->
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,

    columns: {
      type: Array,
      eg: [
        {
          label: 'xxx',
          value: 'xxx',
        },
      ],
      defautl: () => [],
    },

    //
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    // 确定
    confirm(v) {
      this.$emit('handleConfirm', v);
      this.cancel();
    },

    // 取消
    cancel() {
      this.$emit('update:show', false);
    },

    //
  },
};
</script>

<style lang="scss" scoped>
</style>
